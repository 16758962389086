<template>
  <div class="main_helium">
    <div class="m-4">
      <h2>Do you have some questions?</h2>
      <br>
      <h4>Need more informations?</h4>
      <h4>Feel free to contact one of the links below</h4>
      <br>
      <p class="my-4 py-2">
        <a href="https://invoxia.zendesk.com/hc/en-us/articles/4403332177425-About-Helium" class="btn btn-outline-primary btn-block">
          <span style="font-size: large;">About Helium</span></a>
      </p>
      <p class="my-4 py-2">
        <a href="https://explorer.helium.com/coverage/" class="btn btn-outline-primary btn-block">
          <span style="font-size: large;">Check your coverage</span></a>
      </p>
      <p class="my-4 py-2">
        <a href="https://docs.helium.com/faq/helium-network/" class="btn btn-outline-primary btn-block">
          <span style="font-size: large;">Helium FAQ</span></a>
      </p>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/style.scss";

export default {
  name: "HeliumOperator",
  mounted: function () {
     window.zE('webWidget', 'hide');
  }
};
</script>